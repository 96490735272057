import React from "react"
import { Link } from "gatsby"
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton } from 'react-twitter-embed';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import LeftMenu from './leftMenu'

const Contact = () => (
  <Layout>
    <SEO title="National Student Essay Competition" />

    <div className="subpageContainer">

      <LeftMenu active="contact" />
      <div className="body">
         
        <h1 style={{marginTop: '55px'}}>Contact</h1>
        
        <p>Thanks for your interest in the National Student Essay Competition!  If you have any questions, send a message to <a href="mailto:studentessays@mklpr.com">studentessays@mklpr.com</a>.</p>
        
        <p>Please spread word about the National Student Essay Competition on Social Media and follow us on Twitter. We welcome your tweets!</p>
        
        <span style={{display: 'inline-block', marginRight: '10px'}}>
        <TwitterFollowButton 
          screenName={'freepressessays'} 
          options={{
            showCount: false,
            showScreenName: false,
            size: 'large'
          }}
        />
        </span>

        <span style={{display: 'inline-block'}}>
          <TwitterShareButton
            url={'https://mklpr.com/national-student-essay-competition'}
            options={{ text: '#StudentsForPressFreedom', via: 'freepressessays', size: 'large' }}
          />
          </span>
          

        <section  style={{display: "none"}}>
            <a href="https://twitter.com/freepressessays" className="twitter">
                <div className="icon">
                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.414"><path d="M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.37-1.337.64-2.085.79-.598-.64-1.45-1.04-2.396-1.04-1.812 0-3.282 1.47-3.282 3.28 0 .26.03.51.085.75-2.728-.13-5.147-1.44-6.766-3.42C.83 2.58.67 3.14.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.41-.02-.61-.058.42 1.304 1.63 2.253 3.07 2.28-1.12.88-2.54 1.404-4.07 1.404-.26 0-.52-.015-.78-.045 1.46.93 3.18 1.474 5.04 1.474 6.04 0 9.34-5 9.34-9.33 0-.14 0-.28-.01-.42.64-.46 1.2-1.04 1.64-1.7z" fillRule="nonzero"/></svg>
                </div>

                <div className="content">
                  <h1>Twitter</h1>
                  {/* <TwitterFollowButton
                    screenName={'freepressessays'}
                    data-show-count={false}
                    data-show-creen-name={false}
                    data-size={'large'}
                  /> */}

                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="freepressessays"
                    options={{height: 'auto'}}
                  />

                  <span>@freepressessays</span>
                  
                </div>
        </a>
      </section>

      </div>
    </div>

    

  </Layout>
)

export default Contact
